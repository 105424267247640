import superagent from 'superagent';
const { BroadcastChannel } = require('broadcast-channel');
const reloadChannel = new BroadcastChannel('bv360_broadcast_reload_channel');

reloadChannel.onmessage = msg => {
  console.log(msg);
  window.location.reload();
};

export default class ApiClient {
  methods = ['get', 'post', 'put', 'patch', 'del', 'delete'];

  formatUrl = (path, segment = null) => {
    if (path.match(/https?|localhost/)) return path;
    const adjustedPath = path[0] !== '/' ? `/${path}` : path;
    return `${segment ? segment : this.config.apiSegment}${adjustedPath}`;
  };

  client = method => (path, { params, segment = null, data, type = 'json', timeout } = {}) =>
    new Promise((resolve, reject) => {
      const request = superagent[method](this.formatUrl(path, segment));
      request.redirects(0);
      request.timeout(this.config.apiTimeout);

      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      if (!this.config.omitBVHeaders) {
        request.set('BIOVENTUS-CLIENT-ID', 'exogen-direct');
        const userId = localStorage.getItem('user_id');
        request.set('BIOVENTUS-USER-ID', String(userId));
      }

      if (csrfToken) request.set('X-CSRFToken', csrfToken);

      if (params) {
        if (
          method.toLowerCase() === 'post' ||
          method.toLowerCase() === 'put' ||
          method.toLowerCase() === 'patch'
        ) {
          request.type(type).send(params);
        } else {
          request.query(params);
        }
      }

      if (__SERVER__ && req.get('cookie')) {
        request.set('cookie', req.get('cookie'));
      }

      if (data) {
        request.send(data);
      }

      if (timeout) {
        request.timeout(timeout);
      }

      request.end((err, { body, ...rest } = { body: {}, ...rest }) => {
        if (err) {
          const { status, header } = rest;
          if (status === 401 && header['login-required'] === 'true') {
            localStorage.removeItem('user_id');
            window.location.replace(this.config.apiRedirect || '/mybv360/app');
          } else if (
            status === 401 &&
            header &&
            header.hasOwnProperty('user-change') &&
            header['user-change'] === 'true'
          ) {
            reloadChannel.postMessage('reloading for because of potential user change');
          }
          return reject({ body, ...rest });
        } else {
          return resolve({ body, ...rest });
        }
      });
    });

  constructor(config) {
    this.socket = {};
    this.config = config;

    this.methods.forEach(method => {
      this[method] = this.client(method);
    });
  }

  static empty() {}
}
