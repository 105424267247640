import React from 'react';

const captureForSentry = (details, message) => {
  if (__SENTRY__) {
    Sentry.configureScope(scope => {
      scope.setExtra('details', details);
    });
    Sentry.captureMessage(message);
  }
};

export default function useGoNative() {
  const hasGoNative = navigator.userAgent.indexOf('gonative') > -1;

  return { hasGoNative };
}
