import React, { PureComponent } from 'react';
import { errorValues } from './utils';

export default class TextAreaField extends PureComponent {
  render() {
    const {
      field,
      form: { setFieldValue },
      customLabel,
      label,
      placeholder,
      maxLength,
      disabled,
      rows,
      style
    } = this.props;
    const { fieldTouched, fieldError, errorClass } = errorValues(this.props);

    return (
      <div className={errorClass} style={style}>
        <label>{customLabel || label}</label>
        <div>
          <textarea
            {...field}
            disabled={disabled}
            rows={rows ? rows : '10'}
            maxLength={maxLength}
            placeholder={placeholder ? placeholder : label}
            style={{ minWidth: '100%' }}
            onChange={evt => setFieldValue(field.name, evt.target.value)}
            className="form-control textarea-ios"
            value={field.value || ''}
          />
          {fieldTouched && fieldError && <span className="has-error">{fieldError}</span>}
        </div>
      </div>
    );
  }
}
