import React, { Component, PureComponent } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import useIsMobile from 'exogen/hooks/isMobile';
import { errorValues, inputClassNames } from './utils';

import 'react-datepicker/dist/react-datepicker.css';

// const dateWithOffset = val => {
//   const utcDate = new Date(val);
//   return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000) || null;
// };

const popperModifiers = {
  preventOverflow: {
    enabled: true
  }
};

class ButtonDatePicker extends PureComponent {
  render() {
    return (
      <button type="button" className="btn btn-secondary btn--fw" onClick={this.props.onClick}>
        {this.props.value ? this.props.value : 'Select a Date'}
      </button>
    );
  }
}

ButtonDatePicker.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

const popperPros = { position: 'fixed', zIndex: '9999' };

const onFocus = e => {
  if (navigator.userAgent.indexOf('gonative') > -1) {
    e.target.blur();
  } else {
    e.target.focus();
  }
};
export default function DatePickerField({
  field,
  surgicalFormField,
  form,
  disabled,
  maxDate,
  minDate,
  placeholder,
  hideValidStyles,
  label,
  surgicalFormClass = null,
  highlightDates = [],
  validateUntouched
}) {
  const isMobile = useIsMobile();
  const { fieldTouched, fieldError, errorClass } = errorValues({ field, form, validateUntouched });
  const { setFieldValue } = form;
  const selectedValue = (field.value && new Date(field.value)) || null;

  let inputClasses = hideValidStyles
    ? 'form-control'
    : inputClassNames({ field, form }, 'form-control');

  let divClasses = 'input--date-picker';

  if (surgicalFormClass) {
    inputClasses = inputClasses + ' ' + surgicalFormClass;
    divClasses = divClasses + ' ' + surgicalFormClass;
  }
  return (
    <div className={errorClass}>
      {label && <label>{label}</label>}
      <div className={divClasses}>
        <DatePicker
          {...field}
          portalId={`portal-${field.name}`}
          className={inputClasses}
          dateFormat="MM/dd/yyyy"
          disabled={disabled}
          dropdownMode="select"
          maxDate={maxDate}
          minDate={minDate}
          selected={selectedValue}
          onChange={val => {
            setFieldValue(field.name, val);
          }}
          onFocus={onFocus}
          onCalendarClose={
            surgicalFormField
              ? () => {}
              : () =>
                  selectedValue
                    ? setFieldValue(field.name, moment(selectedValue).format('M/DD/YYYY'))
                    : null
          }
          placeholderText={placeholder ? placeholder : 'Select a date...'}
          showMonthDropdown
          showYearDropdown
          disabledKeyboardNavigation
          highlightDates={highlightDates}
          popperProps={popperPros}
          popperModifiers={popperModifiers}
          withPortal={isMobile}
        />

        {fieldTouched && fieldError && <span className="has-error">{fieldError}</span>}
      </div>
    </div>
  );
}

DatePickerField.propTypes = {
  field: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  outputFormat: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
