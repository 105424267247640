import { useState, useEffect } from 'react';
import useGonative from 'exogen/hooks/gonative';

export default function useIsMobile() {
  const { hasGoNative } = useGonative();
  const [initialized, setInitialized] = useState(false);
  const [width, setWidth] = useState(undefined);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!initialized && width <= 430) {
      setMobile(true);
    }

    if (!initialized && width > 430) {
      setMobile(false);
    }

    if (width && !initialized) {
      setInitialized(true);
    }
  }, [initialized, setInitialized, width]);

  // console.log('mobile', mobile);
  // console.log('width', width);

  return hasGoNative || mobile;
}
